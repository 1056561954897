import React, { FC, useEffect, useRef } from "react";
import { useGetLang } from "../../../hooks/useGetLang";

interface StepAudioPlayerProps {
  school: string;
  round: string;
  step: string;
}

const StepAudioPlayer: FC<StepAudioPlayerProps> = ({ school, round, step }) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const lang = useGetLang();

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.load();
    }
  }, [school, round, step]);

  if (school !== "1") {
    return null;
  }

  if (lang === "en") {
    return null;
  }

  return (
    <audio controls ref={audioRef}>
      <source
        src={`/audio/okuskoli${school}-lota${round}-skref${step}.mp3`}
        type="audio/mpeg"
      />

      <p>
        Your browser does not support HTML audio, but you can
        <a href="audio-file.mp3">download the audio file here.</a>.
      </p>
    </audio>
  );
};

export default StepAudioPlayer;
